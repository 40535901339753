import React from "react";
import PropertyThumb from "./PropertyThumb";
import data from "../../static/data.json";

import "./PropertyThumb.css";

const PropertyThumbList = () => {
  return (
    <div className="property-thumb-list">
      {data.map((property, idx) => (
        <PropertyThumb list={property} key={idx} imgIdx={idx} />
      ))}
    </div>
  );
};

export default PropertyThumbList;
