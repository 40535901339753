import TopBanner from "../component/TopBanner/TopBanner";
import SearchBar from "../component/SearchBar/SearchBar";
import FunctionButton from "../component/FunctionButton/FunctionButton";
import FunctionImg from "../component/FunctionImg/FunctionImg";
import PropertyCardList from "../component/PropertyCard/PropertyCardList";

const homePage = () => {
  return (
    <>
      <TopBanner search={SearchBar} />
      <FunctionButton />
      <FunctionImg />
      <PropertyCardList />
    </>
  );
};

export default homePage;
