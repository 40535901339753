import React from "react";
import { useState } from "react";
import "./SearchBar.css";

const SearchBar = () => {
  const [display, setDisplay] = useState(false);

  const viewMoreHandler = () => {
    setDisplay(!display);
  };
  return (
    <div className="SearchBar">
      <div className="SearchInnerDiv">
        <div className="Line-1">
          <div className="search-type">
            <select className="search-drop" name="search" id="Type">
              <option value="buy">Buy</option>
              <option value="rent">Rent</option>
              <option value="sold">Sold</option>
              <option value="leased">Leased</option>
              <option value="offmarket">Off Market</option>
            </select>
          </div>
          <div className="search-key-word">
            <input
              type="text"
              name="SearchKeyWord"
              id="search"
              className="search-keyword-input"
              placeholder="Search by Suburb, Postcode or Keywords"
            />
          </div>
          <div className="search-btn">
            <button>Search</button>
          </div>
        </div>
        <div className="Line-2">
          <div className="suburb">
            <input type="checkbox" name="surrounding" id="surrounding" />
            <span>Surrounding Suburbs</span>
          </div>
          <div className="SearchOption" onClick={viewMoreHandler}>
            <i className="las la-plus"></i>View More Options
          </div>
        </div>
        <div className={display ? "Line-3" : "Line-3-Hide"}>
          <div>
            <span>Category</span>
            <select name="category" id="Type">
              <option value="any">any</option>
              <option value="apartment">apartment</option>
              <option value="house">house</option>
              <option value="semi-detached">semi detached</option>
              <option value="townhouse">townhouse</option>
              <option value="unit">unit</option>
            </select>
          </div>
          <div>
            <span>Price Min</span>
            <select name="price-min" id="Type">
              <option value="any">any</option>
              <option value="100000">$1,000,000</option>
              <option value="200000">$2,000,000</option>
              <option value="300000">$3,000,000</option>
              <option value="400000">$4,000,000</option>
              <option value="500000">$5,000,000</option>
            </select>
          </div>
          <div>
            <span>Price Max</span>
            <select name="price-max" id="Type">
              <option value="any">any</option>
              <option value="100000">$1,000,000</option>
              <option value="200000">$2,000,000</option>
              <option value="300000">$3,000,000</option>
              <option value="400000">$4,000,000</option>
              <option value="500000">$5,000,000</option>
            </select>
          </div>
          <div>
            <span>Beds</span>
            <select name="beds" id="Type">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div>
            <span>Baths</span>
            <select name="Baths" id="Type">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div>
            <span>Parking</span>
            <select name="Parking" id="Type">
              <option value="any">any</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
