import React from "react";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import PropertyCard from "./PropertyCard";
import data from "../../static/data.json";
import images from "../../static/imgs.json";

import "./PropertyCard.css";
import "@brainhubeu/react-carousel/lib/style.css";

const PropertyCardList = () => {
  return (
    <div className="slider-list">
      <Carousel
        plugins={[
          "arrows",
          "infinite",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 4,
            },
          },
        ]}
      >
        {data.map((property, idx) => (
          <PropertyCard list={property} image={images[idx]} key={idx} />
        ))}
      </Carousel>
    </div>
  );
};

export default PropertyCardList;
