import React from "react";
import "./FunctionButton.css";

const FunctionButton = () => {
  const functionButtons = [
    {
      title: "what's my place worth",
      uri: "/#",
    },
    {
      title: "off-market listing alert",
      uri: "/#",
    },
    {
      title: "book appraisal",
      uri: "/#",
    },
    {
      title: "book inspection",
      uri: "/#",
    },
  ];

  return (
    <div className="func-btn-div">
      {functionButtons.map((funcBtn, idx) => {
        return (
          <div className="func-btn-inner-div" key={idx}>
            <a href={funcBtn.uri} className="func-btn-a">
              <div className="func-btn">
                <span>{funcBtn.title}</span>
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default FunctionButton;
