import React from "react";
import "./Footer.css";
import logoTransparent from "../../static/img/Tianyi-Realty-logos_transparent.png";

const Footer = () => {
  return (
    <div className="tianyi-footer">
      <img src={logoTransparent} alt="" className="footer-logo" />
      <div className="tyaddress">
        <div>
          <i className="las la-map-marker"></i>
          <span>Ground Floor 1-5 Railway St Chatswood NSW 2067</span>
        </div>
        <div>
          <i className="las la-phone"></i>
          <span>02 9170 1818</span>
        </div>
        <div>
          <i className="las la-envelope"></i>
          <span>info@tianyirealty.com.au</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
