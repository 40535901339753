import "./PropertyCard.css";

const PropertyCard = (props) => {
  const { suburb, street, bedroom, bathroom, carspace, title } = props.list;
  const thumbnail = require("../../static/img/properties/" + props.image);
  return (
    <div className="property-card">
      <div
        className="property-thumbnail"
        style={{ backgroundImage: "url(" + thumbnail + ")" }}
      ></div>
      <div className="property-info">
        <div className="property-title">
          <span>{title}</span>
        </div>
        <div className="property-add">
          <span>{street}</span>
          <span>{suburb}</span>
        </div>
        <div className="property-detail">
          <div>
            <span>
              <i className="las la-bed"></i>
            </span>
            <span>{bedroom ? bedroom : "-"}</span>
          </div>
          <div>
            <span>
              <i className="las la-bath"></i>
            </span>
            <span>{bathroom ? bathroom : "-"}</span>
          </div>
          <div>
            <span>
              <i className="las la-car"></i>
            </span>
            <span>{carspace ? carspace : "-"}</span>
          </div>
        </div>
      </div>
      <div className="property-footer">
        <i className="las la-clock"></i>
        <i className="las la-envelope"></i>
        <i className="las la-heart"></i>
      </div>
    </div>
  );
};

export default PropertyCard;
