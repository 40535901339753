import ContactForm from "../component/ContactForm/ContactForm";

const contactPage = () => {
  return (
    <>
      <div style={{ maxWidth: "100%", margin: "2rem 5rem 2rem 5rem" }}>
        <iframe
          title="MAP"
          src="https://www.google.com/maps/d/u/2/embed?mid=19MFSadK_CdqRrnyU2hVL7QuMOn0t-xud&ehbc=2E312F"
          width="100%"
          height="500"
          style={{ border: 0, marginTop: "-60px" }}
        ></iframe>
      </div>
      <ContactForm />
    </>
  );
};

export default contactPage;
