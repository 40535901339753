import SearchBar from "../component/SearchBar/SearchBar";
import PropertyThumbList from "../component/PropertyThumb/PropertyThumbList";

const rentPage = () => {
  return (
    <>
      <h2 style={{ textAlign: "center" }}>Properties for Rent</h2>
      <div style={{ margin: "2rem 1% 2rem 1%" }}>
        <SearchBar />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "2rem 10% 2rem 10%",
        }}
      >
        <PropertyThumbList />
      </div>
    </>
  );
};

export default rentPage;
