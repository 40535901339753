import React from "react";
import { Link } from "react-router-dom";
import routes from "@/router";
import logo from "../../static/img/Tianyi-Realty-logos.jpeg";
import "./TopMenu.css";

const TopMenu = () => {
  return (
    <>
      <div className="TopBar">
        <img src={logo} alt="logo" className="logoImg" />
        <div className="TopMenu">
          {routes.map((route, index) => {
            if (route.onMenu) {
              return (
                <span key={index} className="MenuItem">
                  <Link to={route.path}>{route.title}</Link>
                </span>
              );
            }
          })}
        </div>
      </div>
      <div className="placerholder-div"></div>
    </>
  );
};

export default TopMenu;
