import "./PropertyThumb.css";
import images from "../../static/imgs.json";

const PropertyThumb = (props) => {
  const { suburb, bedroom, bathroom, carspace } = props.list;
  const thumbnail = require("../../static/img/properties/" +
    images[props.imgIdx]);

  return (
    <>
      <div
        className="property-photo"
        style={{ backgroundImage: "url(" + thumbnail + ")" }}
      >
        <div className="property-summary-bar">
          <div className="left">
            <div>
              <span>
                <i className="las la-bed"></i>
                {bedroom}
              </span>
            </div>
            <div>
              <span>
                <i className="las la-bath"></i>
                {bathroom}
              </span>
            </div>
            <div>
              <span>
                <i className="las la-car"></i>
                {carspace}
              </span>
            </div>
          </div>
          <div className="right">
            <span>{suburb}</span>
          </div>
        </div>
        <div className="property-event-footer">
          <i className="las la-clock"></i>
          <i className="las la-envelope"></i>
          <i className="las la-heart"></i>
        </div>
      </div>
    </>
  );
};

export default PropertyThumb;
