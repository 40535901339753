import aboutPage from "@/views/aboutPage";
import buyPage from "@/views/buyPage";
import contactPage from "@/views/contactPage";
import homePage from "@/views/homePage";
import rentPage from "@/views/rentPage";
import page404 from "@/views/page404";

const routes = [
  {
    path: "/",
    title: "Home",
    onMenu: true,
    component: homePage,
  },
  {
    path: "/buy",
    title: "Buy",
    onMenu: true,
    component: buyPage,
  },
  {
    path: "/rent",
    title: "Rent",
    onMenu: true,
    component: rentPage,
  },
  {
    path: "/about",
    title: "About",
    onMenu: true,
    component: aboutPage,
  },
  {
    path: "/contact",
    title: "Contact",
    onMenu: true,
    component: contactPage,
  },
  {
    path: "*",
    title: "404",
    onMenu: false,
    component: page404,
  },
];

export default routes;
