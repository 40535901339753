const aboutPage = () => {
  return (
    <div
      style={{
        marginLeft: "1rem",
        marginRight: "1rem",
        // minHeight: "70vh",
        paddingLeft: "15rem",
        paddingRight: "15rem",
        paddingTop: "10rem",
      }}
    >
      <h1 style={{ textAlign: "center" }}>About us</h1>

      <p
        style={{
          fontSize: "35px",
          textAlign: "justify",
          height: "calc(100vh - 65px - 300px)",
        }}
      >
        Tianyi Realty is an energetic local agency, with an enthusiasm for
        success. Our team at Tianyi Realty will go above and beyond to exceed
        your expectation. We provide the best service with the backing of
        experienced and dedicated real estate people. We also invested in the
        best system, technology, and procedures to ensure your property
        transaction is a success.
      </p>
    </div>
  );
};

export default aboutPage;
