import React from "react";
import BannerImage from "../../static/img/pexels-max-vakhtbovych-6438762.jpg";
import TextBanner from "../TextBanner/TextBanner";
import "./TopBanner.css";

const TopBanner = (props) => {
  const SearchBar = props.search;
  const divstyle = {
    backgroundImage: "url(" + BannerImage + ")",
  };
  return (
    <div className="BannerDiv" style={divstyle}>
      <SearchBar />
      <TextBanner />
    </div>
  );
};

export default TopBanner;
