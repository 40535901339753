import React from "react";
import "./FunctionImg.css";

const FunctionImg = () => {
  const funcImgs = [
    {
      title: "off market listings",
      uri: "/#",
      img: require("../../static/img/funcj-img-001.jpg"),
    },
    {
      title: "video library",
      uri: "/#",
      img: require("../../static/img/funcj-img-002.jpg"),
    },
    {
      title: "blog",
      uri: "/#",
      img: require("../../static/img/funcj-img-003.jpg"),
    },
    {
      title: "our results",
      uri: "/#",
      img: require("../../static/img/funcj-img-004.jpg"),
    },
  ];
  return (
    <div className="func-img-div">
      {funcImgs.map((funcImg, idx) => {
        return (
          <div
            key={idx}
            className="func-img"
            style={{
              backgroundImage: "url(" + funcImg.img + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <span>{funcImg.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default FunctionImg;
