import React from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const contactFormHandler = () => {
    alert("Message sent");
  };

  return (
    <>
      <h3 style={{ textAlign: "center", marginTop: "3rem" }}>
        Leave a Message to us:
      </h3>
      <div className="ContactForm">
        <p>
          <label htmlFor="FirstName">First Name *</label>
          <br />
          <input type="text" name="" id="FirstName" />
        </p>
        <p>
          <label htmlFor="LastName">Last Name *</label>
          <br />
          <input type="text" name="" id="LastName" />
        </p>
        <p>
          <label htmlFor="Email">Email *</label>
          <br />
          <input type="text" name="" id="Email" />
        </p>
        <p>
          <label htmlFor="Phone">Phone *</label>
          <br />
          <input type="text" name="" id="Phone" />
        </p>
        <p>
          <label htmlFor="Suburb">Suburb *</label>
          <br />
          <input type="text" name="" id="Suburb" />
        </p>
        <p>
          <label htmlFor="Message">Message</label>
          <br />
          <textarea name="" id="Message" cols="30" rows="10"></textarea>
        </p>
        <p style={{ textAlign: "center" }}>
          <button className="contactBtn" onClick={contactFormHandler}>
            Send
          </button>
        </p>
      </div>
    </>
  );
};

export default ContactForm;
