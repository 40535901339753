import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "@/router";
import TopMenu from "./component/TopMenu/TopMenu";
import Footer from "./component/Footer/Footer";

function App() {
  return (
    <>
      <Router>
        <TopMenu />
        <div className="main-content">
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              );
            })}
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
